import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import Home from "../views/Home.vue"
import Contacts from '../views/Contacts.vue'
import Register from "../views/Register.vue"
import Profile from '../views/Profile.vue'
import Private from '../views/Private.vue'
import RokuHome from '../views/roku/Home.vue'
import RokuOverview from '../views/roku/Overview.vue'
import RokuSetup from '../views/roku/Setup.vue'
import RokuUsage from '../views/roku/Usage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contacts
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: "/private/:conversation",
    name: "Private",
    props: true, 
    component: Private
  },
  {
    path: '/roku',
    name: 'Roku Homepage',
    component: RokuHome
  },
  {
    path: '/roku/overview',
    name: 'Roku Overview',
    component: RokuOverview
  },
  {
    path: '/roku/setup',
    name: 'Roku Setup',
    component: RokuSetup
  },
  {
    path: '/roku/usage',
    name: 'Roku Usage',
    component: RokuUsage
  },
  {
    path: "/NotFound",
    component: () => import("../views/NotFound.vue")
  }
]

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
