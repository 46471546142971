import { GetterTree } from 'vuex';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';

import { State } from './state';

export type Getters = {
  error(state: State): string | null;
  warning(state: State): string | null;
  info(state: State): string | null;
  success(state: State): string | null;
  
  errorCount(state: State): number;
  warningCount(state: State): number;
  infoCount(state: State): number;
  successCount(state: State): number;
}

export const getters: GetterTree<State, RootState> & Getters = {
  error: (state) => state.errorData,
  warning: (state) => state.warningData,
  info: (state) => state.infoData,
  success: (state) => state.successData,

  errorCount: (state) => state.errorCount,
  warningCount: (state) => state.warningCount,
  infoCount: (state) => state.infoCount,
  successCount: (state) => state.successCount,
};
