
export type State = {
  errorData: string | null;
  errorCount: number;
  warningData: string | null;
  warningCount: number;
  infoData: string | null;
  infoCount: number;
  successData: string | null;
  successCount: number;
}

export const state: State = {
  errorData: null,
  errorCount: 0,
  warningData: null,
  warningCount: 0,
  infoData: null,
  infoCount: 0,
  successData: null,
  successCount: 0
};
