import { ActionTree, ActionContext } from 'vuex';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';

import { State } from './state';
import { Getters } from './getters';
import { Mutations } from './mutations';
import { NotificationsMutationTypes } from './mutation-types';
import { NotificationsActionTypes } from './action-types';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>
  };
} & Omit<ActionContext<State, RootState>, 'commit' | 'getters'>

export interface Actions {
  [NotificationsActionTypes.SET_ERROR](
    { commit, getters }: AugmentedActionContext,
    error: string | null, // Obsolete in here but left as an example
  ): Promise<void>;
  [NotificationsActionTypes.SET_WARNING](
    { commit, getters }: AugmentedActionContext,
    warning: string | null, // Obsolete in here but left as an example
  ): Promise<void>;
  [NotificationsActionTypes.SET_INFO](
    { commit, getters }: AugmentedActionContext,
    info: string | null, // Obsolete in here but left as an example
  ): Promise<void>;
  [NotificationsActionTypes.SET_SUCCESS](
    { commit, getters }: AugmentedActionContext,
    success: string | null, // Obsolete in here but left as an example
  ): Promise<void>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [NotificationsActionTypes.SET_ERROR]({ commit, getters }, error: string | null) : Promise<void> {
    return await new Promise<void>(resolve => {
        commit(NotificationsMutationTypes.SET_ERROR, error);
        let count : number = getters.errorCount
        setTimeout(() => {
          if (count == getters.errorCount){
            commit(NotificationsMutationTypes.SET_ERROR, null);
          }
          resolve();
        }, 5000)
      });
  },
  async [NotificationsActionTypes.SET_WARNING]({ commit, getters }, warning: string | null) : Promise<void> {
    return await new Promise<void>(resolve => {
        commit(NotificationsMutationTypes.SET_WARNING, warning);
        let count : number = getters.warningCount
        setTimeout(() => {
          if (count == getters.warningCount){
            commit(NotificationsMutationTypes.SET_WARNING, null);
          }
          resolve();
        }, 5000)
      });
  },
  async [NotificationsActionTypes.SET_INFO]({ commit, getters }, info: string | null) : Promise<void> {
    return await new Promise<void>(resolve => {
        commit(NotificationsMutationTypes.SET_INFO, info);
        let count : number = getters.infoCount
        setTimeout(() => {
          if (count == getters.infoCount){
            commit(NotificationsMutationTypes.SET_INFO, null);
          }
          resolve();
        }, 5000)
      });
  },
  async [NotificationsActionTypes.SET_SUCCESS]({ commit, getters }, success: string | null) : Promise<void> {
    return await new Promise<void>(resolve => {
        commit(NotificationsMutationTypes.SET_SUCCESS, success);
        let count : number = getters.successCount
        setTimeout(() => {
          if (count == getters.successCount){
            commit(NotificationsMutationTypes.SET_SUCCESS, null);
          }
          resolve();
        }, 5000)
      });
  },
};
