
import { defineComponent, ref, onMounted, onUnmounted, computed, onBeforeUpdate } from 'vue';
import { mapGetters } from 'vuex';
import { useStore } from '@/store';
import { router } from '@/router';

import { ConversationActionTypes } from '@/store/modules/conversation/action-types';
import { NotificationsActionTypes } from '@/store/modules/notifications/action-types';
import socket from '@/socket';
//import { adminId } from '^/admin';
import moment from 'moment';

import Qrcode from '@/components/Qrcode.vue';
import Timestamp from '@/components/Timestamp.vue';
import { ERROR_PACKET } from 'engine.io-parser/build/esm/commons';
import { Message, Conversation } from '@/@types';

const store = useStore();

export default defineComponent({
  name: 'Conversation',
  props: {
    convKey: {
      type: String,
      required: true
    },
  },
  components: {
    Qrcode,
    Timestamp
  },
  
  setup(props, ctx) {   
    
    const message = ref('');
    const selected = ref('');
    const adminId : string = '0000000000000000'

    onMounted(async ()=>{
      message.value = ''
      selected.value = ''
      await store.dispatch(ConversationActionTypes.JOIN_CONVERSATION, props.convKey)
    })

    onBeforeUpdate(async ()=>{
      message.value = ''
      selected.value = ''
      await store.dispatch(ConversationActionTypes.JOIN_CONVERSATION, props.convKey)
    })

    onUnmounted(async ()=>{
      await store.dispatch(ConversationActionTypes.LEAVE_CONVERSATION, null)
    })


    function submit () {
      socket.emit('submitMessage',{conversation:props.convKey, payload:message.value}, (err: string|null) => {
        if (err) {
          socket.emit('submitMessage',{conversation:props.convKey, payload:message.value}, (err: string|null) => {
            message.value = ''
            if (err) {
              store.dispatch(NotificationsActionTypes.SET_ERROR, err)
            }
          })
        } else {
          message.value = ''
        }
      })
    };

    const submitDisabled = computed( () : boolean => {
      return message.value.length == 0
    });

    function toggle(msg:Message) : void {
      if (msg.key === selected.value) {
        selected.value = ''
      } 
      else {
        selected.value = msg.key || ''
      }
    }

    function canDelete(msg:Message) : boolean {
      if (msg.key === selected.value && (
        (store.getters.id && msg.sender === store.getters.id) ||
        adminId === store.getters.id
      )) return true
      return false
    }

    async function doDelete(msg:Message) : Promise<void> {
      return new Promise<void>(resolve => {
        if (store.getters.id) {
          socket.emit("deleteMessage", {conversation:props.convKey,sender:msg.sender,timestamp:msg.timestamp,count:msg.count}, (err: string|null) : void => {
            resolve()
          })
        }
        else {
          resolve()
        }
      });
    }

    function isPrivate(userId:string) : boolean {
      let participants = store.getters.getConversationParticipants
      if (store.getters.id && userId && userId !== store.getters.id &&
        participants.length == 2 &&
        participants.includes(store.getters.id) &&
        participants.includes(userId))
        return true
      return false
    }

    function canPrivate(msg:Message) : boolean {
      if (msg.key === selected.value) {
        if (msg.sender && store.getters.id){
          if (!isPrivate(msg.sender)){
            if (msg.sender !== store.getters.id) {
              return true
            }
          }
        }
      }
      return false
    }

    async function doPrivate(msg:Message) : Promise<void> {
      return new Promise<void>(resolve => {
        if (msg.sender && store.getters.id) {
          socket.emit("createConversation", [store.getters.id, msg.sender], (err: string|null, conversation: Conversation|null) : void => {
            if (conversation) {
              router.push("/private/"+conversation.key)
            }
            resolve()
          })
        }
        else {
          resolve()
        }
      });
    }

    function isAdmin() : boolean {
      return adminId === store.getters.id;
    }

    function isModerator() : boolean {
      return false;
    }

    return {
      submit,
      message,
      submitDisabled,
      selected,
      toggle,
      canDelete,
      canPrivate,
      doPrivate,
      doDelete,
      isAdmin,
      isModerator
    };
  },
  computed: {
    ...mapGetters(['getConversationParticipants','getConversationMessages','id']),
  },
})
