import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_text_field, {
            modelValue: _ctx.key,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.key) = $event)),
            label: "Key",
            variant: "filled",
            shaped: ""
          }, null, 8, ["modelValue"]),
          _createVNode(_component_v_card_actions, null, {
            default: _withCtx(() => [
              (_ctx.key)
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 0,
                    onClick: _ctx.register
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("REGISTER WITH THE KEY")
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : (_openBlock(), _createBlock(_component_v_btn, {
                    key: 1,
                    onClick: _ctx.fetchNewKey
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("REQUEST A NEW KEY")
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}