import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex justify-space-between flex-grow-1" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_qrcode = _resolveComponent("qrcode")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_timeline_item = _resolveComponent("v-timeline-item")!
  const _component_timestamp = _resolveComponent("timestamp")!
  const _component_v_slide_x_transition = _resolveComponent("v-slide-x-transition")!
  const _component_v_timeline = _resolveComponent("v-timeline")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { style: {"max-width":"800px"} }, {
    default: _withCtx(() => [
      _createVNode(_component_v_divider),
      _createVNode(_component_v_timeline, {
        density: "compact",
        side: "end"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_timeline_item, {
            "fill-dot": "",
            class: "white--text mb-12",
            "dot-color": "white",
            size: "large"
          }, {
            icon: _withCtx(() => [
              _createVNode(_component_qrcode, {
                scale: 2,
                code: _ctx.id
              }, null, 8, ["code"])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                class: "v-timeline-item-fix",
                modelValue: _ctx.message,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.message) = $event)),
                "hide-details": "",
                label: "Leave a comment...",
                density: "compact",
                onKeydown: _withKeys(_ctx.submit, ["enter"])
              }, {
                append: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    class: "mx-0",
                    variant: "text",
                    onClick: _ctx.submit,
                    disabled: _ctx.submitDisabled
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Post ")
                    ]),
                    _: 1
                  }, 8, ["onClick", "disabled"])
                ]),
                _: 1
              }, 8, ["modelValue", "onKeydown"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_slide_x_transition, { group: "" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getConversationMessages, (item) => {
                return (_openBlock(), _createBlock(_component_v_timeline_item, {
                  key: item.key,
                  class: "mb-4",
                  "dot-color": "white",
                  size: "small",
                  onClick: ($event: any) => (_ctx.toggle(item))
                }, {
                  icon: _withCtx(() => [
                    _createVNode(_component_qrcode, {
                      code: item.sender
                    }, null, 8, ["code"])
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                      _createElementVNode("div", {
                        innerHTML: item.payload
                      }, null, 8, _hoisted_2),
                      _createVNode(_component_timestamp, {
                        class: "flex-shrink-0",
                        timestamp: item.timestamp
                      }, null, 8, ["timestamp"])
                    ]),
                    (_ctx.canPrivate(item))
                      ? (_openBlock(), _createBlock(_component_v_btn, {
                          key: 0,
                          class: "float-left",
                          variant: "outlined",
                          onClick: ($event: any) => (_ctx.doPrivate(item))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(" PRIVATE MESSAGE ")
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))
                      : _createCommentVNode("", true),
                    (_ctx.canDelete(item))
                      ? (_openBlock(), _createBlock(_component_v_btn, {
                          key: 1,
                          class: "float-right",
                          variant: "outlined",
                          onClick: ($event: any) => (_ctx.doDelete(item))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(" DELETE ")
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}