import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list_group = _resolveComponent("v-list-group")!
  const _component_qrcode = _resolveComponent("qrcode")!
  const _component_nickname = _resolveComponent("nickname")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_text = _resolveComponent("v-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar_items = _resolveComponent("v-toolbar-items")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_Notifications = _resolveComponent("Notifications")!
  const _component_Captcha = _resolveComponent("Captcha")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_navigation_drawer, {
        modelValue: _ctx.drawer,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.drawer) = $event)),
        temporary: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_list, {
            nav: "",
            dense: "",
            opened: _ctx.open,
            "onUpdate:opened": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.open) = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_list_item, {
                title: "Home",
                "prepend-icon": "mdi-home",
                to: "/"
              }),
              _createVNode(_component_v_list_group, null, {
                activator: _withCtx(({ props }) => [
                  _createVNode(_component_v_list_item, _mergeProps(props, {
                    "prepend-icon": "mdi-account-multiple-outline",
                    title: "Roku"
                  }), null, 16)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_v_list_item, {
                    title: "Overview",
                    to: "/roku/overview"
                  }),
                  _createVNode(_component_v_list_item, {
                    title: "Setup",
                    to: "/roku/setup"
                  }),
                  _createVNode(_component_v_list_item, {
                    title: "Usage",
                    to: "/roku/usage"
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_list_group, { value: "Conversations" }, {
                activator: _withCtx(({ props }) => [
                  _createVNode(_component_v_list_item, _mergeProps(props, {
                    "prepend-icon": "mdi-account-multiple-outline",
                    title: "Conversations"
                  }), null, 16)
                ]),
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.conversations, (conversation) => {
                    return (_openBlock(), _createBlock(_component_v_list_item, {
                      key: conversation.key,
                      to: _ctx.conversationRoute(conversation)
                    }, {
                      prepend: _withCtx(() => [
                        _createVNode(_component_qrcode, {
                          code: _ctx.conversationName(conversation)
                        }, null, 8, ["code"])
                      ]),
                      title: _withCtx(() => [
                        _createVNode(_component_nickname, {
                          userId: _ctx.conversationName(conversation)
                        }, null, 8, ["userId"])
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  }), 128))
                ]),
                _: 1
              }),
              _createVNode(_component_v_list_item, {
                title: "Contacts",
                "prepend-icon": "mdi-account-multiple-outline",
                to: "/contacts"
              })
            ]),
            _: 1
          }, 8, ["opened"])
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_v_app_bar, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_app_bar_nav_icon, {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.drawer = true))
          }),
          _createVNode(_component_v_spacer),
          (!_ctx.isRegistered)
            ? (_openBlock(), _createBlock(_component_v_toolbar_items, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    flat: "",
                    to: "/register"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Identity")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.isRegistered)
            ? (_openBlock(), _createBlock(_component_v_toolbar_items, { key: 1 }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    flat: "",
                    to: "/profile"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Identity")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_v_main, null, {
        default: _withCtx(() => [
          _createVNode(_component_Notifications),
          _createVNode(_component_Captcha),
          _createVNode(_component_v_container, { fluid: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_router_view)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}