
import { defineComponent, ref, onMounted, onUnmounted } from 'vue';
import { mapGetters, MutationPayload } from 'vuex';
import { useStore, RootState } from '@/store';
import { CaptchaActionTypes } from '@/store/modules/captcha/action-types';
import { CaptchaMutationTypes } from '@/store/modules/captcha/mutation-types';


const store = useStore();

export default defineComponent({
  name: 'LocalizerCard',
  setup() {   

    let show = ref(false);

    // actions
   
    return {
      show
    };
  },
  computed: {
    ...mapGetters(['getCaptcha','confirmCaptcha']),
  },
})
