import { ActionTree, ActionContext, CommitOptions } from 'vuex';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';

import { State } from './state';
import { Getters } from './getters';
import { Mutations } from './mutations';
import { ConversationMutationTypes } from './mutation-types';
import { ConversationActionTypes } from './action-types';

import { socket } from '@/socket';
import { Conversation, ServerToClientMessage, MessageReference } from 'common/@types'
import { Message as StoreMessage } from '@/@types'

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>
  };
} & Omit<ActionContext<State, RootState>, 'commit' | 'getters'>

export interface Actions {
  [ConversationActionTypes.JOIN_CONVERSATION](
    { commit }: AugmentedActionContext,
    conversationId: string, // Obsolete in here but left as an example
  ): Promise<string|null>;
  [ConversationActionTypes.LEAVE_CONVERSATION](
    { commit }: AugmentedActionContext,
    payload: unknown, // Obsolete in here but left as an example
  ): Promise<string|null>;
  [ConversationActionTypes.FEED_CONVERSATION](
    { commit }: AugmentedActionContext,
    payload: ServerToClientMessage, // Obsolete in here but left as an example
  ): void;
  [ConversationActionTypes.DELETE_MESSAGE](
    { commit }: AugmentedActionContext,
    payload: MessageReference, // Obsolete in here but left as an example
  ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ConversationActionTypes.JOIN_CONVERSATION]({ commit }: AugmentedActionContext, conversationId: string) : Promise<string|null> {
    return await new Promise<string|null>(resolve => {
      commit(ConversationMutationTypes.SET_CONVERSATION_MESSAGES, []);
      socket.emit('joinConversation', conversationId, (err:string|null, conversation: Conversation | null) => {
        commit(ConversationMutationTypes.SET_CONVERSATION_PAYLOAD, conversation);
        resolve(err);
      });      
    });
  },
  async [ConversationActionTypes.LEAVE_CONVERSATION]({ commit }: AugmentedActionContext, payload: unknown) : Promise<string|null> {
    return await new Promise<string|null>(resolve => {
      socket.emit('leaveConversation',(err: string|null) => {
        commit(ConversationMutationTypes.SET_CONVERSATION_PAYLOAD, null);
        resolve(err);
      })
    });
  },
  [ConversationActionTypes.FEED_CONVERSATION]({ commit }: AugmentedActionContext, msg: ServerToClientMessage) : void {
    commit(ConversationMutationTypes.PUSH_CONVERSATION_MESSAGE, msg);
  },
  [ConversationActionTypes.DELETE_MESSAGE]({ commit }: AugmentedActionContext, msg: MessageReference) : void {
    commit(ConversationMutationTypes.DELETE_CONVERSATION_MESSAGE, msg);
  },
};
