import { MutationTree } from 'vuex';

import { State } from './state';
import { CaptchaMutationTypes } from './mutation-types';

export type Mutations<S = State> = {
  [CaptchaMutationTypes.SET_CAPTCHA](state: S, payload: string | null): void;
  [CaptchaMutationTypes.SET_CAPTCHA_TEXT](state: S, payload: string | null): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [CaptchaMutationTypes.SET_CAPTCHA](state: State, data: string | null) {
    state.captchaData = data;
  },
  [CaptchaMutationTypes.SET_CAPTCHA_TEXT](state: State, data: string | null) {
    state.captchaTextData = data;
  },
};
