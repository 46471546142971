import {Message} from '@/@types'

export type State = {
  key: string|null;
  private: boolean;
  participants: string[];
  messages: Message[];
  moderators: string[];
}

export const state: State = {
  key: null,
  private: false,
  participants: [],
  messages: [],
  moderators: []
};
