import { MutationTree } from 'vuex';

import type { UserProfileName, UserRoles, UserConversations } from '@/@types';

import { State } from './state';
import { ProfileMutationTypes as MutationTypes } from './mutation-types';
import { UserInfo } from '^/@types';

export type Mutations<S = State> = {
  [MutationTypes.SET_PROFILE](state: S, payload: UserProfileName): void;
  [MutationTypes.SET_ROLES](state: S, payload: UserRoles): void;
  [MutationTypes.SET_PROFILE_KEY](state: S, payload: string|null): void;
  [MutationTypes.SET_PROFILE_ID](state: S, payload: string|null): void;
  [MutationTypes.SET_PROFILE_NICKNAME](state: S, payload: string|null): void;
  [MutationTypes.SET_PROFILE_CONVERSATIONS](state: S, payload: UserConversations): void;
  [MutationTypes.SET_USERINFO_MAP](state: S, payload: UserInfo): void;
  [MutationTypes.CLEAR_USERINFO_MAP](state: S): void;
  [MutationTypes.SET_USERINFO_TIMESTAMP](state: S, payload: number): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_PROFILE](state: State, profile: UserProfileName) {
    state.profileData = profile;
  },
  [MutationTypes.SET_ROLES](state: State, roles: UserRoles) {
    state.rolesData = roles;
  },
  [MutationTypes.SET_PROFILE_KEY](state: State, key: string|null) {
    state.keyData = key;
  },
  [MutationTypes.SET_PROFILE_ID](state: State, id: string|null) {
    state.idData = id;
  },
  [MutationTypes.SET_PROFILE_NICKNAME](state: State, nickname: string|null) {
    state.nicknameData = nickname;
  },
  [MutationTypes.SET_PROFILE_CONVERSATIONS](state: State, conversations: UserConversations) {
    state.conversationsData = conversations;
  },
  [MutationTypes.SET_USERINFO_MAP](state: State, userInfo: UserInfo) {
    for(let i = 0; i < state.userInfosData.length; i++) {
      if (state.userInfosData[i].id == userInfo.id) {
        state.userInfosData[i] = userInfo
        return
      }
    }
    state.userInfosData.push(userInfo)
  },
  [MutationTypes.CLEAR_USERINFO_MAP](state: State) {
    state.userInfosData = []
  },
  [MutationTypes.SET_USERINFO_TIMESTAMP](state: State, timestamp: number) {
    state.userInfosTimestampData = timestamp
  },
};
