
import { defineComponent, ref, onMounted } from 'vue';
import { mapGetters } from 'vuex';
import { useStore } from '@/store';
import { socket } from '@/socket';
import { router } from '@/router';
import { ProfileActionTypes } from '@/store/modules/profile/action-types';
import { NotificationsActionTypes } from '@/store/modules/notifications/action-types';

const store = useStore();

export default defineComponent({
  name: 'Register',
  components: {
  },
  setup() {

    let key = ref('');

    onMounted(() => {
      key.value = '';
    })
    
    function fetchNewKey () {
      socket.emit('fetchNewKey',(k:string|null) => {
        if (k) {
          key.value = k;
        }
      })
    };

    async function register () {
      let err = await store.dispatch(ProfileActionTypes.SET_KEY,key.value);
      if (err) {
        store.dispatch(NotificationsActionTypes.SET_ERROR,err);
      } else {
        store.dispatch(NotificationsActionTypes.SET_SUCCESS,"Well done!");
      }
      router.push('/');
    };

    return {
      key,
      fetchNewKey,
      register
    };
  },
  computed: {

  }
})
