
import { defineComponent, ref } from 'vue';
import { mapGetters } from 'vuex';
import { Conversation } from '@/@types';
import { useStore } from '@/store';

import Qrcode from '@/components/Qrcode.vue';
import Captcha from '@/components/Captcha.vue';
import Nickname from '@/components/Nickname.vue';
import Notifications from '@/components/Notifications.vue';

export default defineComponent({
  name: 'App',
  components: {
    Qrcode,
    Captcha,
    Nickname,
    Notifications
  },
  setup() {
    const store = useStore();

    const drawer = ref(false);
    const open = ref(['Conversations']);
    const expandRoku = ref(false);
    const expandConversations = ref(false);
    function conversationRoute(conversation:Conversation) {
      return "/private/" + conversation.key;
    }
    function conversationName(conversation:Conversation) {
      if (conversation.participants.length == 2) {
        if (conversation.participants[0] == store.getters.id) 
          return conversation.participants[1]
        else 
          return conversation.participants[0]
      }
      return conversation.key;
    }
    return {
      drawer,
      open,
      expandRoku,
      expandConversations,
      conversationRoute,
      conversationName
    };
  },
  computed: {
    ...mapGetters(['isClient', 'isAdmin','isRegistered','conversations']),
  }
})
