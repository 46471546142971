
import { defineComponent, ref, onMounted } from 'vue';
import { mapGetters } from 'vuex';
import { useStore } from '@/store';
import { socket } from '@/socket';
import { router } from '@/router';
import { ProfileActionTypes } from '@/store/modules/profile/action-types';
import { NotificationsActionTypes } from '@/store/modules/notifications/action-types';

const store = useStore();

export default defineComponent({
  name: 'Contacts',
  components: {
  },
  setup() {


    return {

    };
  },
  computed: {

  }
})
