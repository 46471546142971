import { MutationTree } from 'vuex';

import { State } from './state';
import { ConversationMutationTypes } from './mutation-types';
import { Conversation, MessageReference } from 'common/@types';
import { Message } from '@/@types'

export type Mutations<S = State> = {
  [ConversationMutationTypes.SET_CONVERSATION_PAYLOAD](state: S, payload: Conversation|null): void;
  [ConversationMutationTypes.SET_CONVERSATION_MESSAGES](state: S, payload: Message[]): void;
  [ConversationMutationTypes.PUSH_CONVERSATION_MESSAGE](state: S, payload: Message): void;
  [ConversationMutationTypes.DELETE_CONVERSATION_MESSAGE](state: S, payload: MessageReference): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [ConversationMutationTypes.SET_CONVERSATION_PAYLOAD](state: State, payload: Conversation|null) {
    if (payload) {
      state.key = payload.key;
      state.private = payload.private;
      state.participants = payload.participants;
      state.messages = [];
    }
    else {
      state.key = null;
      state.private = false;
      state.participants = [];
      state.messages = [];
    }
  },
  [ConversationMutationTypes.SET_CONVERSATION_MESSAGES](state: State, payload: Message[]) {
    state.messages = payload;
  },
  [ConversationMutationTypes.PUSH_CONVERSATION_MESSAGE](state: State, payload: Message) {
    const key = payload.timestamp + "." + payload.count
    let msgIndex = state.messages.findIndex((a:Message)=>{
      return a.key == key;
    });
    if (msgIndex != -1) {
      state.messages[msgIndex]={
        key: key,
        sender: payload.sender, 
        payload: payload.payload, 
        timestamp: payload.timestamp, 
        count: payload.count
      };
    } else {
      state.messages.push({
        key: key,
        sender: payload.sender, 
        payload: payload.payload, 
        timestamp: payload.timestamp, 
        count: payload.count
      })
    }
    
    state.messages.sort((a:Message, b:Message)=>{
      if (a.timestamp == b.timestamp) {
        return b.count - a.count;
      }
      else
        return b.timestamp - a.timestamp;
    })
  },
  [ConversationMutationTypes.DELETE_CONVERSATION_MESSAGE](state: State, payload: MessageReference) {
    const key = payload.timestamp + "." + payload.count
    let msgIndex = state.messages.findIndex((a:Message)=>{
      return a.key == key;
    });
    if (msgIndex != -1) {
      state.messages.splice(msgIndex, 1);
    } 
    state.messages.sort((a:Message, b:Message)=>{
      if (a.timestamp == b.timestamp) {
        return b.count - a.count;
      }
      else
        return b.timestamp - a.timestamp;
    })
  },
};
