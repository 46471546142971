
import { defineComponent, reactive, toRefs, ref, onBeforeUpdate } from 'vue';
import { router } from '@/router';
import Conversation from '@/components/Conversation.vue';

export default defineComponent({
  name: 'Private',
  props: {
    conversation: {
      type: String,
      required: true
    },
  },
  components: {
    Conversation
  },
  setup(props, ctx) {
    const conversationKey = ref(props.conversation);
    onBeforeUpdate(() => {
      conversationKey.value = props.conversation;
    })
    return { 
      conversationKey 
    }
  }
});
