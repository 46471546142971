
import { defineComponent, reactive, toRefs, ref } from 'vue';

import Conversation from '@/components/Conversation.vue';
import OverviewCard from './cards/OverviewCard.vue';

export default defineComponent({
  name: 'RokuOverview',

  components: {
    Conversation,
    OverviewCard
  },
  setup() {
    const conversationKey = ref('roku-overview')
    
    return { 
      conversationKey 
    }
  }
});
