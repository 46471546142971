
import { defineComponent, ref, onMounted, h, useAttrs } from 'vue';
import qrcode from 'qrcode';

export default defineComponent({
  name: 'qrcode',
  props: {
    code: {
      type: String,
      required: false
    },
    scale: {
      type: Number,
      required: false,
    },
  },
  setup(props, context) {   
    const attrs = useAttrs()
    const code = props.code;
    const canvasEl = ref<HTMLCanvasElement | null>(null)
    const scale = props.scale || 1

    onMounted(()=>{
      if (canvasEl.value === null) {
        //throw new Error("template Error!")
      } else {
        if (code) {
          let dark = '#000'
          if (code.length > 2) {
            let r = code.charCodeAt(0)
            let g = code.charCodeAt(1)
            let b = code.charCodeAt(2)
            dark = '#'
            dark += r < b || r < g ? r.toString(16) : '00'
            dark += g < r || g < b ? g.toString(16) : '00'
            dark += b < r || b < r ? b.toString(16) : '00'
          }
          console.log(dark)
          qrcode.toCanvas(canvasEl.value, code, {scale:scale,margin:1,color:{dark:dark,light:'#FFF'}}, function (error) {
            if (error) console.error(error)
          })
        }
      }
    })
    
    return () => {
      if (code) {
        return h('canvas',{...attrs,ref:canvasEl})
      } else {

      }
      
    };
  },
})
