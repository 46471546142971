import type { UserConversations, UserProfileName, UserRoles } from '@/@types';
import { UserInfo } from '^/@types';

const defaultProfile = 'client';

export type State = {
  profileData: UserProfileName;
  rolesData: UserRoles;
  keyData: string|null;
  idData: string|null;
  nicknameData: string|null;
  conversationsData: UserConversations;
  userInfosData: UserInfo[];
  userInfosTimestampData: number;
}

export const state: State = {
  profileData: defaultProfile,
  rolesData: [],
  keyData: null,
  idData: null,
  nicknameData: null,
  conversationsData: [],
  userInfosData: [],
  userInfosTimestampData: 0
};
