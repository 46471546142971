import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_alert = _resolveComponent("v-alert")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_alert, {
      modelValue: _ctx.showError,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showError) = $event)),
      onClick: _ctx.onErrorDismissed,
      type: "error"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.error), 1)
      ]),
      _: 1
    }, 8, ["modelValue", "onClick"]),
    _createVNode(_component_v_alert, {
      modelValue: _ctx.showWarning,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showWarning) = $event)),
      onClick: _ctx.onWarningDismissed,
      type: "warning"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.warning), 1)
      ]),
      _: 1
    }, 8, ["modelValue", "onClick"]),
    _createVNode(_component_v_alert, {
      modelValue: _ctx.showInfo,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showInfo) = $event)),
      onClick: _ctx.onInfoDismissed,
      type: "info"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.info), 1)
      ]),
      _: 1
    }, 8, ["modelValue", "onClick"]),
    _createVNode(_component_v_alert, {
      modelValue: _ctx.showSuccess,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showSuccess) = $event)),
      onClick: _ctx.onSuccessDismissed,
      type: "success"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.success), 1)
      ]),
      _: 1
    }, 8, ["modelValue", "onClick"])
  ]))
}