import io, { Socket } from 'socket.io-client';
import {ClientToServerEvents, ServerToClientEvents} from '../../common/socket';

const debug = process.env.NODE_ENV !== 'production';
const host = window.location.hostname;
const port = debug ? 3000 : window.location.port;
console.log('Connecting to socket server at ' + host + ':' + port);
// makes Sokect available in your Vue.js app as either "$this.socket" (in your Source) or "{{ $coloredText }}" in your templates
export const socket: Socket<ServerToClientEvents, ClientToServerEvents> = io(host+':'+port);

export default socket;