import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_header_text = _resolveComponent("v-card-header-text")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_layout = _resolveComponent("v-layout")!

  return (_openBlock(), _createBlock(_component_v_layout, {
    row: "",
    "justify-center": ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.confirmCaptcha,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.confirmCaptcha) = $event)),
        "max-width": "450"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_header_text, null, {
                default: _withCtx(() => [
                  _createTextVNode("Are you a human?")
                ]),
                _: 1
              }),
              _createVNode(_component_v_sheet, {
                color: "grey lighten-5",
                innerHTML: _ctx.getCaptcha
              }, null, 8, ["innerHTML"]),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    autofocus: "",
                    modelValue: _ctx.respond,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.respond) = $event)),
                    "hide-details": "",
                    label: "Type the code here...",
                    density: "compact",
                    onKeydown: _withKeys(_ctx.submit, ["enter"])
                  }, null, 8, ["modelValue", "onKeydown"]),
                  _createVNode(_component_v_btn, { onClick: _ctx.submit }, {
                    default: _withCtx(() => [
                      _createTextVNode("SUBMIT")
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_v_btn, { onClick: _ctx.reload }, {
                    default: _withCtx(() => [
                      _createTextVNode("RELOAD")
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}