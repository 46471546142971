import { GetterTree } from 'vuex';

import { Message } from '@/@types';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';

import { State } from './state';

export type Getters = {
  getConversationKey(state: State): string|null;
  getConversationIsPrivate(state: State): boolean;
  getConversationParticipants(state: State): string[];
  getConversationMessages(state: State): Message[];
}

export const getters: GetterTree<State, RootState> & Getters = {
  getConversationKey: (state: State) => state.key,
  getConversationIsPrivate: (state: State) => state.private,
  getConversationParticipants: (state: State) => state.participants,
  getConversationMessages: (state: State) => state.messages,
};
