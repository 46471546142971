// TODO: Remove no-shadow eslint disable after fixed: https://github.com/typescript-eslint/typescript-eslint/issues/2484
// eslint-disable-next-line no-shadow
export enum ProfileMutationTypes {
  SET_PROFILE = 'SET_PROFILE',
  SET_ROLES = 'SET_ROLES',
  SET_PROFILE_KEY = 'SET_PROFILE_KEY',
  SET_PROFILE_ID = 'SET_PROFILE_ID',
  SET_PROFILE_CONVERSATIONS = 'SET_PROFILE_CONVERSATIONS',
  SET_PROFILE_NICKNAME = 'SET_PROFILE_NICKNAME',
  SET_USERINFO_MAP = 'SET_USERINFO_MAP',
  CLEAR_USERINFO_MAP = 'CLEAR_USERINFO_MAP',
  SET_USERINFO_TIMESTAMP = 'SET_USERINFO_TIMESTAMP',
}
