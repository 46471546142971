import { MutationTree } from 'vuex';

import { State } from './state';
import { NotificationsMutationTypes } from './mutation-types';

export type Mutations<S = State> = {
  [NotificationsMutationTypes.SET_ERROR](state: S, payload: string | null): void;
  [NotificationsMutationTypes.SET_WARNING](state: S, payload: string | null): void;
  [NotificationsMutationTypes.SET_INFO](state: S, payload: string | null): void;
  [NotificationsMutationTypes.SET_SUCCESS](state: S, payload: string | null): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [NotificationsMutationTypes.SET_ERROR](state: State, error: string | null) {
    state.errorData = error;
    state.errorCount++;
  },
  [NotificationsMutationTypes.SET_WARNING](state: State, warning: string | null) {
    state.warningData = warning;
    state.warningCount++;
  },
  [NotificationsMutationTypes.SET_INFO](state: State, info: string | null) {
    state.infoData = info;
    state.infoCount++;
  },
  [NotificationsMutationTypes.SET_SUCCESS](state: State, success: string | null) {
    state.successData = success;
    state.successCount++;
  },
};
