
import { defineComponent, reactive, toRefs, ref } from 'vue';

import Conversation from '@/components/Conversation.vue';
import RokuCard from './cards/RokuCard.vue';
import LearningAiCard from './cards/LearnungAICard.vue'
import LocalizerCard from './cards/LocalizerCard.vue'
import SQLiteCard from './cards/SQLiteCard.vue'

export default defineComponent({
  name: 'Home',

  components: {
    Conversation,
    RokuCard,
    LearningAiCard,
    LocalizerCard,
    SQLiteCard
  },
  setup() {
    const conversationKey = ref('homepage')
    
    return { 
      conversationKey 
    }
  }
});
