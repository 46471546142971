import { ActionTree, ActionContext } from 'vuex';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';

import { State } from './state';
import { Getters } from './getters';
import { Mutations } from './mutations';
import { CaptchaMutationTypes } from './mutation-types';
import { CaptchaActionTypes } from './action-types';

import { socket } from '@/socket';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>
  };
} & Omit<ActionContext<State, RootState>, 'commit' | 'getters'>

export interface Actions {
  [CaptchaActionTypes.FETCH_CAPTCHA](
    { commit }: AugmentedActionContext,
    payload: unknown
  ): Promise<void>;
  [CaptchaActionTypes.SET_CAPTCHA](
    { commit }: AugmentedActionContext,
    payload: string | null
  ): void;
  [CaptchaActionTypes.SET_CAPTCHA_TEXT](
    { commit }: AugmentedActionContext,
    payload: string | null
  ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [CaptchaActionTypes.FETCH_CAPTCHA]({ commit }: AugmentedActionContext, payload: unknown) : Promise<void> {
    await new Promise<void>(resolve => {
      socket.emit('fetchCaptcha',(data:string) => {
        commit(CaptchaMutationTypes.SET_CAPTCHA, data);
        resolve();
      });      
    });
  },
  [CaptchaActionTypes.SET_CAPTCHA]({ commit }: AugmentedActionContext, payload:string|null) : void {
    commit(CaptchaMutationTypes.SET_CAPTCHA_TEXT, null);
    commit(CaptchaMutationTypes.SET_CAPTCHA, payload);
  },
  [CaptchaActionTypes.SET_CAPTCHA_TEXT]({ commit }: AugmentedActionContext, payload:string|null) : void {
    commit(CaptchaMutationTypes.SET_CAPTCHA, null);
    commit(CaptchaMutationTypes.SET_CAPTCHA_TEXT, payload);
  },
};
