import { GetterTree } from 'vuex';

import type { UserProfileName, UserRoles, Conversation } from '@/@types';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';

import { State } from './state';
import { UserInfo } from '^/@types';

export type Getters = {
  profile(state: State): UserProfileName;
  roles(state: State): UserRoles;
  key(state: State): string | null;
  id(state: State): string | null;
  nickname(state: State): string | null;
  isClient(state: State): boolean;
  isAdmin(state: State): boolean;
  isRegistered(state: State): boolean;
  conversations(state: State): Conversation[];
  userInfos(state: State): UserInfo[];
  userInfosTimestamp(state: State): number;
}

export const getters: GetterTree<State, RootState> & Getters = {
  profile: (state) => state.profileData,
  roles: (state) => state.rolesData,
  key: (state) => state.keyData,
  id: (state) => state.idData,
  nickname: (state) => state.nicknameData,
  isClient: (state) => state.profileData === 'client',
  isAdmin: (state) => state.profileData === 'admin',
  isRegistered: (state) => state.keyData !== null,
  conversations: (state) => state.conversationsData,
  userInfos: (state) => state.userInfosData,
  userInfosTimestamp: (state) => state.userInfosTimestampData
};
