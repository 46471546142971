
import { defineComponent, ref, onMounted, onUnmounted, onUpdated, h, useAttrs } from 'vue';
import moment from 'moment';

export default defineComponent({
  name: 'timestamp',
  props: {
    timestamp: {
      type: Number,
      required: true
    },
  },
  setup(props, context) {
    const attrs = useAttrs()
    const timestamp = ref(props.timestamp);
    const spanEl = ref<HTMLSpanElement | null>(null)
    let timer : NodeJS.Timer|null = null;

    onMounted(()=>{
      if (spanEl.value === null) {
        throw new Error("Template Error!")
      } else {
        spanEl.value.innerHTML = moment.unix(timestamp.value).fromNow()
        timer = setInterval(() => {
          if (spanEl.value)
            spanEl.value.innerHTML = moment.unix(timestamp.value).fromNow()
        }, 5000); 
      }
    })

    onUnmounted(()=>{
      if (timer) clearInterval(timer);
    })

    onUpdated(()=>{
      if (spanEl.value === null) {
        throw new Error("Template Error!")
      } else {
        spanEl.value.innerHTML = moment.unix(timestamp.value).fromNow()
      }
    })
    
    return () => {
      return h('span',{...attrs,ref:spanEl})
    };
  },
})
