
import { defineComponent, ref, onMounted, onUnmounted } from 'vue';
import { mapGetters, MutationPayload } from 'vuex';
import { useStore, RootState } from '@/store';
import { CaptchaActionTypes } from '@/store/modules/captcha/action-types';
import { CaptchaMutationTypes } from '@/store/modules/captcha/mutation-types';

const store = useStore();

export default defineComponent({
  name: 'Captcha',
  setup() {   

    let unsubscribe : ()=>void = () => {};

    const respond = ref('');

    onMounted(()=>{
      unsubscribe = store.subscribe((mutation: MutationPayload, state: Pick<RootState, 'profile'>) => {
        if (mutation.type == CaptchaMutationTypes.SET_CAPTCHA) {
          respond.value = ''  
        }
      });
    })

    onUnmounted(()=>{
      unsubscribe();
      respond.value = ''
    })

    // actions
    function reload () {
      respond.value = ''
      store.dispatch(CaptchaActionTypes.FETCH_CAPTCHA, '');
    };

    function submit () {
      store.dispatch(CaptchaActionTypes.SET_CAPTCHA_TEXT, respond.value);
    };

    return {
      reload,
      submit,
      respond
    };
  },
  computed: {
    ...mapGetters(['getCaptcha','confirmCaptcha']),
  },
})
