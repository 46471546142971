
import { defineComponent, ref, onMounted, onUnmounted } from 'vue';
import { mapGetters, MutationPayload } from 'vuex';
import { useStore, RootState } from '@/store';
import { CaptchaActionTypes } from '@/store/modules/captcha/action-types';
import { CaptchaMutationTypes } from '@/store/modules/captcha/mutation-types';
import LiteYouTubeEmbed from 'vue-lite-youtube-embed';
import 'vue-lite-youtube-embed/dist/style.css'

const store = useStore();

export default defineComponent({
  name: 'RokuUsageCard',
  components: {
    LiteYouTubeEmbed
  },

  setup() {   

    let show = ref(false);
    let video_id = ref('rOuW8AFrFAY');

    // actions
   
    return {
      show,
      video_id
    };
  },
  computed: {
    ...mapGetters(['getCaptcha','confirmCaptcha']),
  },
})
