
import { useStore } from '@/store';
import { defineComponent, ref, onMounted, h, useAttrs } from 'vue';
import { ProfileActionTypes } from '@/store/modules/profile/action-types';
import { UserInfo } from '^/@types';

const store = useStore();

export default defineComponent({
  name: 'nickname',
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  setup(props, context) {   
    
    const attrs = useAttrs()
    const userId = props.userId;
    const divEl = ref<HTMLDivElement | null>(null)

    onMounted(async ()=>{
      if (divEl.value === null) {
        //throw new Error("template Error!")
      } else {
        let userInfo : UserInfo|null = await store.dispatch(ProfileActionTypes.GET_USER_INFO, userId)
        if (userInfo) {
          divEl.value.innerText = userInfo.nickname || userId
        }
      }
    })

    return () => {
      return h('div',{...attrs,ref:divEl})
    };
  },
})
