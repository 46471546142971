import { Store, MutationPayload } from "vuex";
import type { UserProfileName, UserRoles } from '@/@types';
import { RootState } from '@/store';
import { ProfileActionTypes } from '@/store/modules/profile/action-types';
import { ProfileMutationTypes } from '@/store/modules/profile/mutation-types';
import { CaptchaActionTypes } from '@/store/modules/captcha/action-types';
import { CaptchaMutationTypes } from '@/store/modules/captcha/mutation-types';
import { ConversationActionTypes } from '@/store/modules/conversation/action-types';
import { NotificationsActionTypes } from '@/store/modules/notifications/action-types';
import { Vue } from 'vue-class-component';
import { socket } from '@/socket'
import { MessageReference, ServerToClientMessage, UserInfo } from 'common/@types'

interface Options<RootState> {
  host?: string;
  port?: number;
}

export default function <RootState>(
    options?: Options<RootState>
  ): (store: Store<RootState>) => void {
    options = options || {};
    
    return function (store: Store<RootState>) {  

      store.commit(CaptchaMutationTypes.SET_CAPTCHA,null);
      
      socket.on('confirmCaptcha', (data,callback) => {
        store.dispatch(CaptchaActionTypes.SET_CAPTCHA, data);
        const unsubscribe = store.subscribe((mutation: MutationPayload, state: RootState) => {
          if (mutation.type == CaptchaMutationTypes.SET_CAPTCHA_TEXT) {
            callback(mutation.payload);
            unsubscribe();
          }
        });
      })

      socket.on("connect", () => {
        if (store.getters.key) {
          socket.emit("login", store.getters.key, (err: string|null, info: UserInfo|null) => {
            if (err) {
              store.dispatch(NotificationsActionTypes.SET_ERROR, err)
              //console.error('login', err);
            }
            if (info) {
              store.commit(ProfileMutationTypes.SET_PROFILE_ID, info.id)
              store.commit(ProfileMutationTypes.SET_PROFILE_CONVERSATIONS, info.conversations)
            }
          })
        } else {
          socket.emit('logout', (err:string|null) => {
            if (err) {
              store.dispatch(NotificationsActionTypes.SET_ERROR, err)
              //console.error('logout', err);
            }
          });
        }
      });
      
      socket.on('feedConversation', (msg:ServerToClientMessage) => {
        store.dispatch(ConversationActionTypes.FEED_CONVERSATION, msg);
      })

      socket.on('deleteMessage', (msg:MessageReference) => {
        store.dispatch(ConversationActionTypes.DELETE_MESSAGE, msg);
      })

      socket.on('userInfo', (info: UserInfo)=>{
        if (info) {
          store.commit(ProfileMutationTypes.SET_PROFILE_ID, info.id)
          store.commit(ProfileMutationTypes.SET_PROFILE_CONVERSATIONS, info.conversations)
        }
      })
    };
  }
