import io from 'socket.io-client'
import { createStore, createLogger } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import createResponderPlugin from '@/store/plugins/responder';

// TODO: How to surpass cyclical dependency linting errors cleanly?
// eslint-disable-next-line import/no-cycle
import { store as profile, ProfileStore, State as ProfileState } from '@/store/modules/profile';
// eslint-disable-next-line import/no-cycle
import { store as captcha, CaptchaStore, State as CaptchaState } from '@/store/modules/captcha';
// eslint-disable-next-line import/no-cycle
import { store as conversation, ConversationStore, State as ConversationState } from '@/store/modules/conversation';
// eslint-disable-next-line import/no-cycle
import { store as notifications, NotificationsStore, State as NotificationsState } from '@/store/modules/notifications';

export type RootState = {
  profile: ProfileState;
  captcha: CaptchaState;
  conversation: ConversationState;
  notifications: NotificationsState;
};

export type Store = ProfileStore<Pick<RootState, 'profile'>>
 & CaptchaStore<Pick<RootState, 'captcha'>>
 & ConversationStore<Pick<RootState, 'conversation'>>
 & NotificationsStore<Pick<RootState, 'notifications'>>;

// Plug in logger when in development environment
const debug = process.env.NODE_ENV !== 'production';
const plugins = debug ? [createLogger({})] : [];

// Plug in session storage based persistence
plugins.push(createPersistedState({ storage: window.sessionStorage }));
// Plug socket plugin
plugins.push(createResponderPlugin({}));

export const store = createStore({
  plugins,
  modules: {
    profile,
    captcha,
    conversation,
    notifications
  },
});

export function useStore(): Store {
  return store as Store;
}
