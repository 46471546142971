import { resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_label = _resolveComponent("v-label")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_card_header_text = _resolveComponent("v-card-header-text")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      (_ctx.isRegistered)
        ? (_openBlock(), _createBlock(_component_v_card, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_label, {
                    textContent: _toDisplayString(_ctx.key)
                  }, null, 8, ["textContent"]),
                  _createVNode(_component_v_btn, { onClick: _ctx.unregister }, {
                    default: _withCtx(() => [
                      _createTextVNode("LOGOUT")
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.isRegistered)
        ? (_openBlock(), _createBlock(_component_v_card, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_header_text, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.id), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    autofocus: "",
                    modelValue: _ctx.nickname,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.nickname) = $event)),
                    "hide-details": "",
                    label: "Name visible to others...",
                    density: "compact",
                    onKeydown: _withKeys(_ctx.submit, ["enter"])
                  }, null, 8, ["modelValue", "onKeydown"]),
                  _createVNode(_component_v_btn, { onClick: _ctx.submit }, {
                    default: _withCtx(() => [
                      _createTextVNode("UPDATE")
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (!_ctx.isRegistered)
        ? (_openBlock(), _createBlock(_component_v_btn, {
            key: 2,
            onClick: _ctx.register
          }, {
            default: _withCtx(() => [
              _createTextVNode("REQUEST A NEW KEY")
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}