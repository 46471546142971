
import { defineComponent, computed, ref, onMounted, onUnmounted } from 'vue';
import { mapGetters, MutationPayload } from 'vuex';
import { useStore, RootState } from '@/store';
import { NotificationsActionTypes } from '@/store/modules/notifications/action-types';

const store = useStore();

export default defineComponent({
  name: 'Notifications',
  setup() {   

    const showError = computed( () : boolean => {
      return store.getters.error != null
    });

    const showWarning = computed( () : boolean => {
      return store.getters.warning != null
    });

    const showInfo = computed( () : boolean => {
      return store.getters.info != null
    });

    const showSuccess = computed( () : boolean => {
      return store.getters.success != null
    });

    function onErrorDismissed () {
      store.dispatch(NotificationsActionTypes.SET_ERROR,null)
    }

    function onWarningDismissed () {
      store.dispatch(NotificationsActionTypes.SET_WARNING,null)
    }

    function onInfoDismissed () {
      store.dispatch(NotificationsActionTypes.SET_INFO,null)
    }

    function onSuccessDismissed () {
      store.dispatch(NotificationsActionTypes.SET_SUCCESS,null)
    }

    return {
      showError,
      showWarning,
      showInfo,
      showSuccess,
      onErrorDismissed,
      onWarningDismissed,
      onInfoDismissed,
      onSuccessDismissed
    };
  },
  computed: {
    ...mapGetters(['error','info','warning','success']),
  },
})
