
import { defineComponent, ref, onMounted } from 'vue';
import { mapGetters } from 'vuex';
import { useStore } from '@/store';
import { router } from '@/router';
import { ProfileActionTypes } from '@/store/modules/profile/action-types';
import { NotificationsActionTypes } from '@/store/modules/notifications/action-types';

const store = useStore();

export default defineComponent({
  name: 'Profile',
  components: {
  },
  setup() {

    let nickname = ref(store.getters.nickname)

    async function unregister () {
      let err = await store.dispatch(ProfileActionTypes.SET_KEY, null);
      if (err) {
        store.dispatch(NotificationsActionTypes.SET_ERROR, err);
      }
      router.push('/');
    };

    function register () {
      router.push('/register');
    }

    async function submit () {
      let err = await store.dispatch(ProfileActionTypes.SET_NICKNAME, nickname.value);
      if (err) {
        store.dispatch(NotificationsActionTypes.SET_ERROR, err);
      }
      else {
        store.dispatch(NotificationsActionTypes.SET_SUCCESS, "Well done!");
      }
    };

    return {
      register,
      unregister,
      nickname,
      submit
    };
  },
  computed: {
    ...mapGetters(['isRegistered','key','id']),
  }
})
