import { ActionTree, ActionContext } from 'vuex';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';

import { State } from './state';
import { Getters } from './getters';
import { Mutations } from './mutations';
import { ProfileMutationTypes } from './mutation-types';
import { ProfileActionTypes } from './action-types';

import { socket } from '@/socket';

import { UserInfo } from 'common/socket'
import moment from 'moment';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>
  };
} & Omit<ActionContext<State, RootState>, 'commit' | 'getters'>

export interface Actions {
  [ProfileActionTypes.SET_KEY](
    { commit }: AugmentedActionContext,
    payload: string|null
  ): Promise<string|null>;
  [ProfileActionTypes.SET_NICKNAME](
    { commit }: AugmentedActionContext,
    payload: string|null
  ): Promise<string|null>;
  [ProfileActionTypes.GET_USER_INFO](
    { commit, getters }: AugmentedActionContext,
    payload: string
  ): Promise<UserInfo|null>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ProfileActionTypes.SET_KEY]({ commit }: AugmentedActionContext, key: string|null) : Promise<string|null> {
    if (key) {
      return await new Promise<string|null>(resolve => {
        socket.emit('login', key, (err:string|null, info:UserInfo|null) => {
          if (info) {
            commit(ProfileMutationTypes.SET_PROFILE_KEY, key);
            commit(ProfileMutationTypes.SET_PROFILE_ID, info.id);
            commit(ProfileMutationTypes.SET_PROFILE_NICKNAME, info.nickname);
            commit(ProfileMutationTypes.SET_PROFILE_CONVERSATIONS, info.conversations||[]);
          }
          resolve(err);
        });      
      });
    }
    else {
      return await new Promise<string|null>(resolve => {
        socket.emit('logout', (err:string|null) => {
          commit(ProfileMutationTypes.SET_PROFILE_KEY, key);
          commit(ProfileMutationTypes.SET_PROFILE_ID, null);
          commit(ProfileMutationTypes.SET_PROFILE_NICKNAME, null);
          commit(ProfileMutationTypes.SET_PROFILE_CONVERSATIONS, []);
          resolve(err);
        });
      });
    }
  },
  async [ProfileActionTypes.SET_NICKNAME]({ commit }: AugmentedActionContext, nickname: string|null) : Promise<string|null> {
    return await new Promise<string|null>(resolve => {
      socket.emit('update', {nickname: nickname}, (err:string|null) => {
        if (err == null) {
          commit(ProfileMutationTypes.SET_PROFILE_NICKNAME, nickname);
        }
        resolve(err);
      });      
    });
  },
  async [ProfileActionTypes.GET_USER_INFO]({ commit, getters }: AugmentedActionContext, id: string) : Promise<UserInfo|null> {
    let now = moment().unix()
    if (now > getters.userInfosTimestamp - 5) {
      commit(ProfileMutationTypes.CLEAR_USERINFO_MAP,undefined);
      commit(ProfileMutationTypes.SET_USERINFO_TIMESTAMP,now);
    }
    return await new Promise<UserInfo|null>(resolve => {
      console.warn(id)
      const userInfo = getters.userInfos.find((ui:UserInfo) => {ui.id == id})
      if(userInfo){
        resolve(userInfo);
      }
      else {
        socket.emit('userInfo', id, (err:string|null, userInfo:UserInfo|null) => {
          if (err == null) {
            if (userInfo != null) {
              commit(ProfileMutationTypes.SET_USERINFO_MAP, userInfo);
              resolve(userInfo);
            }
            else {
              resolve(null);
            }
          }
          else {
            resolve(null);
          }
        });      
      }
    });
  },
};