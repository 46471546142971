import { GetterTree } from 'vuex';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';


import { State } from './state';

export type Getters = {
  getCaptcha(state: State): string | null;
  confirmCaptcha(state: State): boolean;
}

export const getters: GetterTree<State, RootState> & Getters = {
  getCaptcha: (state) => state.captchaData,
  confirmCaptcha: (state) => state.captchaData != null,
};
